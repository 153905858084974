import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Mobile, withBreakpoint } from 'core/components/breakpoint';
import Scroller from 'core/components/Scroller';
import EmptyWrapper from 'core/components/EmptyWrapper';

import SectionHeader from 'site/components/SectionHeader';
import Section from 'site/components/Section';

import Question from './Question';

import styles from './index.styl';

export const QUESTION_LIMIT = 5;


function QuestionAndAnswer({ isMobile, questions }) {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [activeWidget, setActiveWidget] = useState(null);

  const onActiveQuestion = useCallback(index => {
    if (activeQuestion === index) {
      setActiveQuestion(null);
      return;
    }
    setActiveQuestion(index);
    setActiveWidget(null);
  }, [activeQuestion]);

  const onActiveWidget = useCallback(index => {
    setActiveWidget(index);
  }, []);

  const ScrollerOrEmpty = isMobile ? Scroller : EmptyWrapper;


  return (
    <Section className={styles.section}>
      <SectionHeader>Нужные ответы<Mobile><br /></Mobile> на <mark>срочные</mark> вопросы</SectionHeader>
      <div className={styles.questions}>
        <ScrollerOrEmpty className='js-questions-scroller' disabled={!!activeQuestion}>
          <div className={styles.questionsGrid} data-active-question={activeQuestion}>
            {questions.map((question, index) => {
              return (
                <Question
                  key={question.id || index}
                  question={question}
                  isActiveQuestion={index === activeQuestion}
                  index={index}
                  onActiveQuestion={onActiveQuestion}
                  activeWidget={activeWidget}
                  onActiveWidget={onActiveWidget}
                  isMobile={isMobile}
                />
              );
            })}
          </div>
        </ScrollerOrEmpty>
      </div>
    </Section>
  );
}

QuestionAndAnswer.propTypes = {
  isMobile: PropTypes.bool,
  questions: PropTypes.array,
};

QuestionAndAnswer.defaultProps = {
  questions: Array(QUESTION_LIMIT).fill({
    attributes: {
      headline: '',
      alternative_headline: '',
    },
  }),
};

export default withBreakpoint(QuestionAndAnswer);
