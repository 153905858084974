import PropTypes from 'prop-types';
import cx from 'classnames';

import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';

import TableOfContentIcon from 'site/icons/TableOfContent.svg';

import styles from './index.styl';

const CONTROL_WIDTH = '64px';
const CONTROL_HEIGHT = '48px';
const PADDING_TOP = '48px';

function Control({ handleModal, controlClassName, theme }) {
  const { blue500, white } = theme.colors;

  return (
    <>
      <div
        className={cx(styles.control, controlClassName)}
        style={{
          '--paddingTop': PADDING_TOP,
          '--circleHeight': CONTROL_HEIGHT,
          '--circleWidth': CONTROL_WIDTH,
        }}
      >
        <style jsx>{`
          .${styles.button}
            color ${white}
            background ${blue500}

            &:hover
            &:active
            &:visited
              color ${white}
        `}</style>
        <div className={styles.sticky}>
          <button
            type='button'
            className={styles.button}
            onClick={handleModal}
            data-qa='control'
          >
            <TableOfContentIcon />
          </button>
        </div>
      </div>
    </>
  );
}

Control.propTypes = {
  handleModal: PropTypes.func.isRequired,
  controlClassName: PropTypes.string,
  theme: themePropTypes(`{
    colors: {
      white,
      blue500,
    }
  }`),
};

/**
 * Фигурное скругление.
 * Задает обтекание текста в точности как и сам контрол "оглавление топика"
 */
export const ControlShape = () => {
  return (
    <span
      className={styles.cut}
      style={{
        '--paddingTop': PADDING_TOP,
        '--circleHeight': CONTROL_HEIGHT,
        '--circleWidth': CONTROL_WIDTH,
      }}
    />
  );
};


export default withTheme(Control);
