import PropTypes from 'prop-types';
import { useCallback, useEffect, useState, useRef } from 'react';
import { Helmet } from 'core/libs/helmet';
import { withRouter, Redirect } from 'core/libs/router';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import Button from 'site/components/Button';
import News from 'site/components/News';
import NewsFeed from 'site/components/NewsFeed';

import {
  getPageNumber,
  getCanonical,
  getTotalPages,
  getTitleTemplate,
  pageLinkConstructor,
} from './utils';

import styles from './index.styl';

const limit = 12;

function Paginator(props, context) {
  const {
    location: {
      pathname,
    },
    match: {
      params: {
        pageSlug,
      },
    },
    history,
    apiParams,
    startOffset,
  } = props;

  const {
    bebopApi,
    defaultSiteMeta: {
      titleTemplate,
    },
  } = context;

  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [content, setContent] = useState([]);
  /**
   * если totalCount не передан извне, то в дальнейшем будем допускать, что
   * вторая встраница всегда существует. После первого запроса мы получим
   * totalCount и уже дальше все будет правильно. Это маленькое допущение
   * позволяет нам не делать лишний запрос с filtered_count с самого начала
   */
  const [filteredCount, setFilteredCount] = useState(props.filteredCount);

  const articlesBlock = useRef(null);
  const prevPage = useRef(null);

  const page = getPageNumber(pageSlug);
  const canonical = getCanonical(pathname);
  const totalPagesCount = getTotalPages(filteredCount, startOffset, limit);
  const titleTemplateWithPage = getTitleTemplate(titleTemplate, page);

  const isLastPage = useCallback(pageNum => {
    return filteredCount <= startOffset + limit * (pageNum - 1);
  }, [startOffset, filteredCount]);

  const showButton = !finished || !filteredCount;

  const include = filterRequiredParams([News], 'include');
  const fields = filterRequiredParams([News], 'fields');

  const fetchArticles = useCallback(loadedPage => {
    if (page === 1) {
      if (isLastPage(page)) setFinished(true);
      return;
    }

    setLoading(true);

    bebopApi
      .getTopics({
        ...apiParams,
        limit,
        sort: '-published_at',
        include,
        fields,
        offset: startOffset + limit * (loadedPage - 2),
        ...!filteredCount && { with_filtered_count: 1 },
      })
      .then(data => {
        if (data?.meta?.filtered_count) {
          setFilteredCount(data.meta.filtered_count);
        }
        return data;
      })
      .then(denormalizeData)
      .then(data => {
        setContent(topics => topics.concat(data));
        setLoading(false);

        if (isLastPage(page)) setFinished(true);
      })
      .catch(e => {
        console.error(e);
        setLoading(false);
        setFinished(true);
      });
  }, [apiParams, bebopApi, page, isLastPage, startOffset, filteredCount, include, fields]);

  const handleClick = () => {
    history.push(
      pageLinkConstructor(canonical, page + 1),
      { infinity: true } // чтобы не было скролла к началу страницы
    );
  };

  /**
   * Эффект срабатывает при смене страницы.
   *
   * При навигации в браузере кнопкой назад page может уменьшаться. В этом случе
   * удаляем подгруженные топики
   *
   * При клике на кнопку page увеличивается - подгружаем топики
   */
  useEffect(() => {
    if (prevPage.current > page) {
      /**
       * На последней странице может быть меньше топиков, чем лимит загрузки
       */
      const lastPageTopicsLength = isLastPage(prevPage.current)
        ? (filteredCount - startOffset) % limit
        : limit;

      setContent(topics => topics.slice(0, -lastPageTopicsLength));
      setFinished(false);
    } else {
      fetchArticles(page);
    }

    prevPage.current = page;
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Эффект срабатывает только при маунте компонента.
   * Если мы заходим по прямой ссылке на какую-то страницу, то делаем скролл
   * до топиков
   */
  useEffect(() => {
    if (page > 1) {
      articlesBlock.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Редирект с первой или несуществующей страницы.
   * !loading нужен, чтобы редирект не сработал несколько раз подряд во время
   * подгрузки топиков
   */
  if (((page === 1 && pageSlug) || (page > totalPagesCount)) && !loading) {
    return <Redirect to={canonical} />;
  }

  return (
    <div ref={articlesBlock}>
      <Helmet titleTemplate={titleTemplateWithPage}>
        <link rel='canonical' href={canonical} />
        {page < totalPagesCount || !totalPagesCount ? (
          <link rel='next' href={pageLinkConstructor(canonical, page + 1)} />
        ) : null}
        {page >= 2 ? (
          <link rel='prev' href={pageLinkConstructor(canonical, page - 1)} />
        ) : null}
      </Helmet>

      <NewsFeed content={content} />

      {showButton && (
        <Button
          onClick={handleClick}
          className={styles.button}
          loading={loading}
          disabled={loading}
          minWidth='100%'
          size='large'
        >
          Загрузить ещё
        </Button>
      )}
    </div>
  );
}

Paginator.propTypes = {
  location: PropTypes.object,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      tagType: PropTypes.string,
      pageSlug: PropTypes.string,
    }),
  }),
  /**
   * Если общее кол-во топиков уже получено во вне, то нет смысла его получать
   * внутри компонента
   */
  filteredCount: PropTypes.number,
  /**
   * Общие параметры запроса к апишке за топиками в пагинации. например, если это
   * страница рубрики то это будет { rubric: rubricSlug }
   */
  apiParams: PropTypes.object.isRequired,
  /**
   * Offset, с которого начинаем запрашивать топики для второй страницы.
   * Например, если на первой странице у нас 20 топиков, то startOffset указываем 20
   */
  startOffset: PropTypes.number.isRequired,
};

Paginator.contextTypes = {
  bebopApi: PropTypes.object,
  defaultSiteMeta: PropTypes.shape({
    titleTemplate: PropTypes.string,
  }),
};

Paginator.displayName = 'Button';

export { Paginator as StorybookComponent };

export default withRouter(Paginator);
