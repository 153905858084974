import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import Page from 'core/components/Page';
import { Indent } from 'core/components/Wrappers';
import { Mobile, withBreakpoint } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';
import bindPropsHOC from 'core/components/bindProps';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import GradientMask from 'site/components/GradientMask';

import PVP from './PVP';
import QuestionAndAnswer, { QUESTION_LIMIT } from './QuestionAndAnswer';
import Reviews from 'site/components/Reviews';
import NewsAndArticles from './NewsAndArticles';
import Guides from './Guides';
import TestDrives from './TestDrives';
import Travels from './Travels';
import OurTeam from './OurTeam';

import CardHorizontal from 'site/cards/CardHorizontal';
import CardVertical from 'site/cards/CardVertical';
import CardRect1 from 'site/cards/CardRect1';
import CardReview from 'site/components/Reviews/Desktop';


function MainPage(props) {
  const {
    loading,
    isMobile,
    questions,
    articles,
    news,
    reviews,
    guides,
    travels,
    testDrives,
  } = props;

  const indent = isMobile ? '16rem' : '80rem';

  return (
    <Page>
      <GradientMask />
      <PVP loading={loading} />
      <QuestionAndAnswer questions={questions} />
      {!loading && (
        <>
          <Mobile><Indent bottom='16rem' /></Mobile>
          <Reviews content={reviews} />
          <Indent bottom={indent} />
          <NewsAndArticles articles={articles} news={news} />
          <Indent bottom={indent} />
          <Guides guides={guides} />
          <Indent bottom={indent} />
          <TestDrives content={testDrives} />
          <Indent bottom={indent} />
          <Travels content={travels} />
          <Indent bottom={isMobile ? '120rem' : '80rem'} />
          <OurTeam />
          <Indent bottom={isMobile ? '240rem' : '80rem'} />
        </>
      )}
    </Page>
  );
}

const contentContract = PropTypes.shape({
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  count: PropTypes.number,
});

MainPage.propTypes = {
  isMobile: PropTypes.bool,
  articles: contentContract,
  news: contentContract,
  questions: PropTypes.array,
  guides: contentContract,
  testDrives: contentContract,
  travels: contentContract,
  reviews: contentContract,
  loading: PropTypes.bool,
};

const dataProvider = resolve({
  questions: ({ bebopApi, consoleError, questions }) => {
    if (questions) return questions;

    return bebopApi
      .getTopics({
        limit: QUESTION_LIMIT,
        topic_type: 'question',
        include: 'content,image',
        fields: 'headline',
        sort: 'published_at',
      })
      .then(denormalizeData)
      .catch(consoleError('articles on main page', []));
  },
  articles: ({ bebopApi, consoleError, articles }) => {
    if (articles) return articles;

    const topicsPromise = bebopApi
      .getTopics({
        autocompleted_list_limit: 4,
        list: 'statya-na-glavnoy',
        topic_type: 'article',
        visibility: 'main_page',
        include: filterRequiredParams([CardHorizontal, CardVertical], 'include'),
        fields: filterRequiredParams([CardHorizontal, CardVertical], 'fields'),
      })
      .then(denormalizeData);

    const countPromise =  bebopApi
      .getTopics({
        topic_type: 'article',
        with_filtered_count: 1,
        fields: '',
      })
      .then(data => data?.meta.filtered_count);

    return Promise.all([topicsPromise, countPromise])
      .then(([topics, count]) => {
        return { topics, count };
      })
      .catch(consoleError('articles on main page', { topics: [], count: 0 }));
  },
  news: ({ bebopApi, consoleError, news }) => {
    if (news) return news;

    return bebopApi
      .getTopics({
        limit: 4,
        topic_type: 'news',
        visibility: 'main_page',
        include: filterRequiredParams([CardRect1], 'include'),
        fields: filterRequiredParams([CardRect1], 'fields'),
        with_filtered_count: 1,
      })
      .then(data => ({
        topics: denormalizeData(data),
        count: data?.meta.filtered_count,
      }))
      .catch(consoleError('news on main page', { topics: [], count: 0 }));
  },
  reviews: ({ bebopApi, consoleError, reviews }) => {
    if (reviews) return reviews;

    const topicsPromise = bebopApi
      .getTopics({
        list: 'obzory-na-glavnoy',
        sort: 'list',
        include: filterRequiredParams([CardReview], 'include'),
        fields: filterRequiredParams([CardReview], 'fields'),
      })
      .then(denormalizeData);

    const countPromise =  bebopApi
      .getTopics({
        topic_type: 'car_review',
        with_filtered_count: 1,
        fields: '',
      })
      .then(data => data?.meta.filtered_count);

    return Promise.all([topicsPromise, countPromise])
      .then(([topics, count]) => {
        return { topics, count };
      })
      .catch(consoleError('reviews on main page', { topics: [], count: 0 }));
  },
  testDrives: ({ bebopApi, consoleError, testDrives }) => {
    if (testDrives) return testDrives;

    return bebopApi
      .getTopics({
        // TODO: делаем запрос без лимита с расчетом на то, что по началу
        // тест-драйвов будет не много. После запуска надо будет сделать логику
        // динамической подгрузки слайдов
        // limit: 1,
        topic_type: 'test_drive',
        visibility: 'main_page',
        include: filterRequiredParams([TestDrives], 'include'),
        fields: filterRequiredParams([TestDrives], 'fields'),
        with_filtered_count: 1,
      })
      .then(data => ({
        topics: denormalizeData(data),
        count: data?.meta.filtered_count,
      }))
      .catch(consoleError('test_drives on main page', { topics: [], count: 0 }));
  },
  travels: ({ bebopApi, consoleError, travels }) => {
    if (travels) return travels;

    return bebopApi
      .getTopics({
        limit: 5,
        topic_type: 'travel',
        visibility: 'main_page',
        include: filterRequiredParams([Travels], 'include'),
        fields: filterRequiredParams([Travels], 'fields'),
        with_filtered_count: 1,
      })
      .then(data => ({
        topics: denormalizeData(data),
        count: data?.meta.filtered_count,
      }))
      .catch(consoleError('travel on main page', { topics: [], count: 0 }));
  },
  guides: ({ bebopApi, consoleError, guides }) => {
    if (guides) return guides;

    return bebopApi
      .getTopics({
        limit: 3,
        topic_type: 'guide',
        visibility: 'main_page',
        include: 'image',
        fields: 'link,headline,list_headline,announce',
        with_filtered_count: 1,
      })
      .then(data => ({
        topics: denormalizeData(data),
        count: data?.meta.filtered_count,
      }))
      .catch(consoleError('articles on main page', { topics: [], count: 0 }));
  },
});

const EnhancedMainPage = withBreakpoint(MainPage);

const Loader = bindPropsHOC({ loading: true })(EnhancedMainPage);

export default withPageHocs(dataProvider, Loader)(EnhancedMainPage);
