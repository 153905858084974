import { memo, useRef, useEffect, useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { Mobile, Desktop, withBreakpoint } from 'core/components/breakpoint';
import { NegativeMobile } from 'core/components/Wrappers';

import Link from 'core/components/Link';

import Logo from 'site/icons/Logo.svg';
import CloseSlim from 'site/icons/CloseSlim';

import Search from 'site/icons/Search.svg';
import Burger from 'site/icons/Burger.svg';

import ShapkaMenu from './ShapkaMenu';

import styles from './index.styl';

const linkLogo = 'https://sberauto.com';

function SiteShapka(props) {
  const {
    theme,
    isMenuOpened,
    setMenuOpenedState,
    menuRef,
    closeRef,
    isMobile,
    handleSearchSubmit,
    inputRef,
  } = props;

  const {
    black,
    blue700,
  } = theme.colors;
  const [isStickyHeaderVisible, setIsStickyHeaderVisible] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isBoundaryPassed, setIsBoundaryPassed] = useState(false);
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const boundaryRef = useRef(null);

  const iconSize = isMobile ? '16rem' : '24rem';
  const widthLogo = isMobile ? '132rem' : '150rem';
  const heightLogo = isMobile ? '22rem' : '24rem';

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.journalLink}
          text-decoration none
          text-transform uppercase
          color ${theme.colors.blue700}
          font 600 20rem/1 ${theme.fonts.display}
      `}</style>
    </scope>
  );
  const WrapperComponent = isMobile ? NegativeMobile : 'div';

  const MenuComponent = isMobile ? <Burger width={iconSize} height={iconSize} /> : 'Меню';

  const journalComponent = (
    <Link to='/' className={scoped.wrapClassNames(styles.journalLink)}>
      Журнал
    </Link>
  );

  const observerCallback = ([entry]) => {
    const boundaryBottom = entry.boundingClientRect.bottom;
    const windowHeight = document.documentElement.clientHeight;

    if (!entry.isIntersecting && boundaryBottom < windowHeight) {
      setIsBoundaryPassed(true);
    } else if (window.scrollY === 0) {
      setIsStickyHeaderVisible(false);
    } else {
      setIsBoundaryPassed(false);
    }
  };

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    const scrollDelta = currentScrollY - lastScrollY;

    if (isMenuOpened) {
      return;
    }

    if (scrollDelta > 0 && isBoundaryPassed) {
      setIsHeaderHidden(true);
    }

    if (scrollDelta < 0 && isBoundaryPassed) {
      if (!isStickyHeaderVisible) {
        setIsStickyHeaderVisible(true);
      }
      setIsHeaderHidden(false);
    }

    setLastScrollY(currentScrollY);
  }, [isMenuOpened, lastScrollY, isBoundaryPassed, isStickyHeaderVisible]);

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback);
    const boundaryNode = boundaryRef.current;

    if (boundaryNode) {
      observer.observe(boundaryNode);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      if (boundaryNode) {
        observer.unobserve(boundaryNode);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, isMobile]);

  return (
    <WrapperComponent>
      <div className={cx(styles.shapka, {
        [styles.menuOpened]: isMenuOpened,
        [styles.fixed]: isStickyHeaderVisible,
        [styles.hidden]: isHeaderHidden,
      })}
      >
        <style jsx>{`
          .${styles.button}
            color ${black}
            background-color transparent
            font 400 24rem/33rem ${theme.fonts.display}
            :global(.desktop) &
              &:hover
                color ${blue700}
                transition color 0.3s, background-color 0.3s

          .${styles.searchInput}
            font 400 24rem/33rem ${theme.fonts.display}
            :global(.mobile) &
              font 400 16rem/16rem ${theme.fonts.display}
        `}</style>
        <Desktop>
          <Link to={linkLogo} className={styles.logo}>
            <Logo width={widthLogo} height={heightLogo} />
          </Link>
        </Desktop>
        <div className={styles.controls}>
          <div className={styles.controlsContainer}>
            <div className={styles.searchBlock}>
              <button
                className={cx(styles.button, styles.search)}
                onClick={setMenuOpenedState}
              >
                <Search width={iconSize} height={iconSize} />
              </button>
            </div>
            <div className={styles.menuBlock}>
              <button
                className={cx(styles.button, styles.menuButton)}
                ref={closeRef}
                onClick={setMenuOpenedState}
              >
                {isMenuOpened
                  ? <CloseSlim width='24rem' height='24rem' />
                  : MenuComponent
                }
              </button>
              <div className={styles.menuContent} ref={menuRef}>
                <ShapkaMenu
                  handleSearchSubmit={handleSearchSubmit}
                  inputRef={inputRef}
                  isFixedShapka={isStickyHeaderVisible}
                />
              </div>
            </div>
          </div>
          <Desktop>{journalComponent}</Desktop>
        </div>
        <Mobile>{journalComponent}</Mobile>
        <scoped.styles />
      </div>
      <div
        className={styles.boundary}
        ref={boundaryRef}
        style={{ height: isStickyHeaderVisible ? '56rem' : '0' }}
      />
      <div className={styles.overlay} />
    </WrapperComponent>
  );
}

SiteShapka.propTypes = {
  isMenuOpened: PropTypes.bool,
  setMenuOpenedState: PropTypes.func,
  handleSearchSubmit: PropTypes.func,
  inputRef: PropTypes.object,
  theme: PropTypes.object,
  menuRef: PropTypes.object,
  closeRef: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default memo(withBreakpoint(SiteShapka));
