import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import modelPropTypes, {
  topicAttributes,
} from 'core/utils/prop-types/model';

import Section from 'site/components/Section';
import SectionHeader from 'site/components/SectionHeader';
import ArticlesFeed from 'site/components/ArticlesFeed';
import NewsFeed from 'site/components/NewsFeed';

import CardFullwidth from 'site/cards/CardFullwidth';
import CardPvp2 from 'site/cards/CardPvp2';

import styles from './index.styl';

function MoreArticles(props) {
  const {
    articles,
    isMobile,
    sectionTitle,
    link,
    fullWidthTopic,
    showPublishedAt,
    showAuthors,
  } = props;

  if (articles?.length === 0) return null;

  const firstBlock = articles.slice(0, 4);
  const secondBlock = articles.slice(4);

  const MobileCard = isMobile ? CardPvp2 : CardFullwidth;

  return (
    <div className={styles.container}>
      {fullWidthTopic && (
        <MobileCard content={fullWidthTopic} />
      )}
      <Section withBackground={!isMobile} className={styles.articles}>
        {firstBlock.length > 0 && (
          <>
            <SectionHeader link={link}>{sectionTitle}</SectionHeader>
            <ArticlesFeed
              content={firstBlock}
              showPublishedAt={showPublishedAt}
              showAuthors={showAuthors}
            />
          </>
        )}
      </Section>
      {secondBlock.length > 0 && (
        <NewsFeed content={secondBlock} />
      )}
    </div>
  );
}

MoreArticles.propTypes = {
  isMobile: PropTypes.bool,
  articles: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  sectionTitle: PropTypes.string,
  link: PropTypes.string,
  fullWidthTopic: PropTypes.object,
  showAuthors: PropTypes.bool,
  showPublishedAt: PropTypes.bool,
};

export default withBreakpoint(MoreArticles);
