import PropTypes from 'prop-types';
import { useRef } from 'react';
import cx from 'classnames';

import resolveRelationships from 'core/utils/relationships';

import withTheme from 'core/components/theme';
import Texts from 'core/components/Texts';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import SmartImage from 'core/components/SmartImage';

import Button from 'site/components/Button';

import ArrowTopRight from 'site/icons/ArrowTopRight';
import ArrowRightMedium from 'site/icons/ArrowRightMedium';
import Close from 'site/icons/Close';
import ArrowBack from 'site/icons/ArrowBack';

import styles from './index.styl';

const relationships = resolveRelationships(['content', 'image'], {});

function Question(props) {
  const {
    question,
    isActiveQuestion,
    onActiveQuestion,
    onActiveWidget,
    activeWidget,
    index,
    theme,
    isMobile,
  } = props;

  const {
    attributes: {
      headline: title,
    },
  } = question;

  const {
    content: {
      widgets,
    },
    image: {
      versions,
    },
  } = relationships(question);

  const questionRef = useRef(null);

  const resetActiveQuestion = () => {
    onActiveQuestion(null);
  };

  const resetActiveWidget = () => {
    onActiveWidget(null);
  };

  const onSetActiveQuestion = () => {
    if (!question) return;

    onActiveQuestion(index);

    /**
     * Дальнейшие расчеты нужны только для мобилки, т.к в мобилке элементы находятся
     * в скроллере и при выборе вопроса нам нужно доскролить пользователя так, чтобы
     * выбранный вопрос был в центе экрана
     */
    if (!isMobile) return;

    const questionEl = questionRef.current;
    const scrollerEl = questionEl.closest('.js-questions-scroller');
    const questionsEl = questionEl.parentElement;

    const questionsStyles = getComputedStyle(questionsEl);

    const questionWidth = questionEl.clientWidth;
    const questionsGap = parseInt(questionsStyles.getPropertyValue('gap'), 10);
    const questionsSideIndent = parseInt(questionsStyles.getPropertyValue('padding-left'), 10) * 2;

    /**
     * Вычисляем левый маржин у блока, который нужен для того, чтобы плавно
     * работала анимация смещения блоков влевую сторону при выборе 4го и 5го вопроса.
     * Это то же самое что вычисляется в css таким способом:
     * --activeQuestionWidth calc(100vw - 32rem)
     * margin-left calc(0rem - var(--activeQuestionWidth) + var(--questionWidth))
     */
    const margin = index > 2
      ? document.documentElement.clientWidth - questionsSideIndent - questionWidth
      : 0;

    const left = (questionWidth + questionsGap) * index - margin;

    scrollerEl && scrollerEl.scroll({
      left,
      behavior: 'smooth',
    });
  };

  const isActiveWidget = isActiveQuestion && activeWidget !== null;

  return (
    <div
      className={cx(
        styles.question,
        isActiveQuestion && styles.activeQuestion,
        isActiveWidget && styles.activeWidget,
      )}
      {...isMobile && !isActiveQuestion && {
        onClick: onSetActiveQuestion,
      }}
      ref={questionRef}
    >
      <style jsx>{`
        .${styles.question}
          background ${theme.colors.gray100}

          &.${styles.activeQuestion}
            background ${theme.colors.white}

        .${styles.questionLevel2Icon}
          color ${theme.colors.blue500}

        .${styles.questionLevel2Title}
          border-color ${theme.colors.gray100}
      `}</style>

      <SmartImage
        versions={versions}
        className={styles.questionImage}
        maxWidth={isMobile ? 42 : 112}
        aspecrRatio={1}
      />

      <div className={styles.questionControl}>
        <Close className={styles.questionClose} onClick={resetActiveQuestion} />
        <ArrowBack className={styles.questionBack} onClick={resetActiveWidget} />
      </div>

      <div className={styles.questionContent}>
        <Texts type='title4' className={styles.questionTitle}>
          {title}
        </Texts>

        {widgets && (
          <div className={styles.questionLevel2Content}>
            <div className={styles.questionLevel2List}>
              {widgets.map((widget, i) => (
                <div
                  key={widget.id}
                  className={styles.questionLevel2Title}
                  onClick={() => onActiveWidget(i)}
                >
                  <Texts tag='span' type={isMobile ? 'title2' : 'body'}>{widget.attributes.title}</Texts>
                  <ArrowRightMedium className={styles.questionLevel2Icon} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {isActiveWidget && (
        <div className={styles.widget}>
          <Texts type='title4' className={styles.questionTitle}>
            {widgets[activeWidget].attributes.title}
          </Texts>
          <MarkdownWrapper className={styles.widgetContent}>
            {widgets[activeWidget].attributes.body}
          </MarkdownWrapper>
        </div>
      )}

      <Button
        onClick={onSetActiveQuestion}
        className={styles.questionButton}
      >
        <ArrowTopRight />
      </Button>
    </div>
  );
}

Question.propTypes = {
  question: PropTypes.object,
  isActiveQuestion: PropTypes.bool,
  onActiveQuestion: PropTypes.func,
  onActiveWidget: PropTypes.func,
  activeWidget: PropTypes.number,
  index: PropTypes.number,
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withTheme(Question);
