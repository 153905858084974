import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import SmartImage from 'core/components/SmartImage';
import ThemeSwitch from 'core/components/ThemeSwitch';
import Texts from 'core/components/Texts';
import { Desktop, withBreakpoint } from 'core/components/breakpoint';
import Link from 'core/components/Link';

import Ogryzok from 'site/components/Ogryzok';
import Button from 'site/components/Button';
import GradientMask from 'site/components/GradientMask';

import styles from './index.styl';

const requiredPayloadImports = ['image'];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
});


function GuidesCard(props) {
  const {
    topic,
    theme: {
      colors,
    },
    index,
    isMobile,
    maxImageHeight,
  } = props;

  if (!topic) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    announce,
  } = topic.attributes || {};

  const even = index % 2 === 0;
  const title = listHeadline || headline;
  const themeName = even ? 'dark' : 'light';
  const backgroundCard = even ? colors.blue500 : colors.green600;

  const {
    image: {
      versions,
    },
  } = relationships(topic);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.card}
          &:after
            background ${backgroundCard}

        .${styles.announce}
          color ${colors.content}

        .${styles.title}
          padding-bottom 2px
          color ${colors.layout}

          :global(.desktop) &
            line-height 1
      `}</style>
    </scope>
  );

  const Wrapper = isMobile ? Link : 'div';

  return (
    <Wrapper className={scope.wrapClassNames(styles.card)} to={link}>
      <Ogryzok
        width={isMobile ? '170rem' : '710rem'}
        height={isMobile ? '55rem' : '210rem'}
        position='top-left'
        content={(
          <Texts
            type={isMobile ? 'title4' : 'accent'}
            className={scope.wrapClassNames(styles.title)}
          >
            {title}
          </Texts>
        )}
        padding={isMobile ? '0 2rem' : '0 48rem 19rem 0'}
        background={backgroundCard}
      >
        {versions && (
          <div className={styles.image}>
            <GradientMask hideEllipsis>
              <SmartImage
                versions={versions}
                maxHeight={maxImageHeight}
                aspectRatio={2.14}
              />
            </GradientMask>
          </div>
        )}
        <Desktop>
          <div className={styles.info}>
            {announce && (
              <Texts type='body' className={scope.wrapClassNames(styles.announce)}>{announce}</Texts>
            )}
            <ThemeSwitch name={themeName}>
              <Button
                link={link}
                size='large'
                minWidth='334rem'
              >
                Читать гайд
              </Button>
            </ThemeSwitch>
          </div>
        </Desktop>
      </Ogryzok>
      <scope.styles />
    </Wrapper>
  );
}

GuidesCard.propTypes = {
  topic: modelPropTypes(topicAttributes),
  theme: PropTypes.object,
  index: PropTypes.number,
  isMobile: PropTypes.bool,
  maxImageHeight: PropTypes.number,
};

export default withTheme(withBreakpoint(GuidesCard));
