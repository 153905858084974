import PropTypes from 'prop-types';

import { aboutFetcher } from 'core/fetchers/about';
import resolve from 'core/resolver/resolve';

import { withBreakpoint, Desktop } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';
import Page from 'core/components/Page';
import { Indent, NegativeMobile } from 'core/components/Wrappers';
import ThemeSwitch from 'core/components/ThemeSwitch';

import RubricHeader from 'site/components/RubricHeader';

import modelPropTypes, { aboutAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import Section from 'site/components/Section';

import TextBlock from './TextBlock';
import OurRedaction from './OurRedaction';

import styles from './index.styl';

const relationships = resolveRelationships(['authors'], {});


function AboutPage(props) {
  const {
    content,
    isMobile,
  } = props;

  const {
    attributes: {
      meta_title: metaTitle,
      meta_description: metaDescription,
    },
  } = content;

  const {
    authors,
  } = relationships(content);

  const indent = isMobile ? '32rem' : '80rem';

  return (
    <Page
      title={metaTitle}
      description={metaDescription}
    >
      <RubricHeader
        showGradientMask
        headlineSize='smaller'
        title='О нашей команде'
        className={styles.rubricHeader}
      />
      <Indent bottom={isMobile ? '16rem' : '80rem'} />
      <NegativeMobile>
        <Section
          className={styles.missionSection}
          withBackground
          withPaddingHorizontal
        >
          <div className={styles.mission}>
            <TextBlock
              title='Наша миссия'
              text='Мы создаем медиа, в центре внимания которого – не столько автомобиль, сколько человек за рулём.
              Мы рассказываем простым языком даже сложные вещи и хотим помочь любому автомобилисту, вне зависимости от его интересов или опыта, получить необходимую и интересную именно ему информацию.'
            />
          </div>
        </Section>
        <Indent  bottom={isMobile ? '20rem' : '80rem'} />
        <Section
          className={styles.redactionSection}
          withPaddingHorizontal
          id='our-experts'
        >
          <TextBlock
            title='Наши эксперты'
            text='Нас объединяет большой опыт в автомобильной журналистике, но при этом мы отменно разбираемся в технике и понимаем, о чём пишем.
            А главное — мы с удовольствием делимся своими знаниями с читателями и готовы ответить на любые вопросы.'
            isOrderText
          />
          <OurRedaction authors={authors} />
        </Section>
        <Desktop><Indent bottom='80rem' /></Desktop>
        <ThemeSwitch name='dark'>
          <div className={styles.office}>
            <div className={styles.info}>
              <TextBlock
                title='Наш офис'
                text='Наша редакция располагается в московском лофт-квартале «Даниловская мануфактура».
                Это бывшая текстильная фабрика между Варшавским шоссе и Новоданиловской набережной.
                Мы работаем плечом к плечу, чтобы быть в полном взаимопонимании и ежедневно создавать приветливое к каждому читателю медиа.'
                isOfficeText
              />
            </div>
          </div>
        </ThemeSwitch>
        <Indent bottom={indent} />
      </NegativeMobile>
    </Page>
  );
}

AboutPage.contextTypes = {
  bebopApi: PropTypes.object,
};

AboutPage.propTypes = {
  content: modelPropTypes(aboutAttributes),
  isMobile: PropTypes.bool,
  about: PropTypes.string,
};

const dataProvider = resolve('content', aboutFetcher());

export default withPageHocs(dataProvider)(withBreakpoint(AboutPage));
