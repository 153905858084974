import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import PubSub from 'pubsub-js';

import resolveRelationships from 'core/utils/relationships';

import SmartImage from 'core/components/SmartImage';

import withTheme from 'core/components/theme';
import { NegativeMobile } from 'core/components/Wrappers';

import { SWIPER_MOUNTED } from 'core/site/constants';

import GradientMask from 'site/components/GradientMask';

import styles from './index.styl';


const relationships = resolveRelationships(['image'], null, {
  image: {
    versions: {},
  },
});

class MainSwipe extends Component {
  static propTypes = {
    /**
     * Топики для отрисовки
     */
    content: PropTypes.array,

    /**
     * Слаг подзаголовка
     */
    altHeadlineSlug: PropTypes.string,

    /**
     * Обработчик смены активного слайда во время перелистывания элементов.
     *
     * В обработчик передается индекс активного слайда и общее кол-во слайдов.
     */
    onActiveIndexChange: PropTypes.func,

    /** @ignore */
    theme: PropTypes.object,

    /**
     * Версия для картинки
     */
    version: PropTypes.string,

    /**
     * Класс для градиентной маски
     */
    gradientClassName: PropTypes.string,

    /**
     * Дополнительные стили для блока travels
     */
    isTravels: PropTypes.bool,
  };

  state = {
    realIndex: 0,
  };

  componentDidMount() {
    if (process.env.BROWSER_RUNTIME) {
      import('swiper')
        .then(module => {
          import('swiper/modules').
            then(effectModule => {
              const Swiper = module.default;
              const { EffectCoverflow } = effectModule;

              const { onActiveIndexChange } = this.props;
              const { handleRealIndexChange } = this;

              this.swiper = new Swiper(this.container, {
                slideClass: styles.slide,
                wrapperClass: styles.wrapper,
                modules: [EffectCoverflow],
                effect: 'coverflow',
                centeredSlides: true,
                loop: true,
                spaceBetween: '-16%',
                slidesPerView: 1,
                speed: 300,
                grabCursor: true,
                longSwipes: false,

                coverflowEffect: {
                  rotate: 0,
                  depth: 350,
                  scale: 0.63,
                  stretch: 20,
                  slideShadows: false,
                },

                on: {
                  activeIndexChange({ activeIndex, realIndex, slides }) {
                    handleRealIndexChange(realIndex);
                    onActiveIndexChange && onActiveIndexChange({
                      index: activeIndex,
                      realIndex: realIndex,
                      count: slides.length,
                    });
                  },
                },
              });
              PubSub.publish(SWIPER_MOUNTED);
            });
        });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.realIndex !== this.state.realIndex;
  }

  componentWillUnmount() {
    if (this.swiper) {
      // Удаляем инстанс и подчищаем стили добавленные в документ.
      this.swiper.destroy(true, true);
    }
  }

  handleRealIndexChange = index => {
    if (index === this.state.realIndex) return;

    this.setState({ realIndex: index });
  };

  // TODO: Придумать что-то получше по стилям для текстов, чем классы для каждого из блоков

  render() {
    const {
      content,
      theme,
      version,
      isTravels,
      altHeadlineSlug,
      gradientClassName,
      ...gradientProps
    } = this.props;

    const slides = content.map((topic, i) => {
      const isActive = i === this.state.realIndex;

      const { image: { versions } } = relationships(topic);

      return (
        <div key={i} className={cx(
          styles.slide,
          {
            [styles._active]: isActive,
            [styles._isTravels]: isTravels,
          }
        )}
        >
          <style jsx>{`
            .${styles.text}
              background-color ${theme.colors.gray100}
          `}</style>
          <div className={styles.contentWrapper}>
            <div className={styles.imageWrapper}>
              <GradientMask className={gradientClassName} {...gradientProps}>
                <SmartImage
                  versions={versions}
                  aspectRatio={1.79}
                  className={styles.image}
                  {...version && { version: version }}
                />
              </GradientMask>
            </div>
            <div className={styles.textWrapper}>
              <div className={styles.text}>
                <span className={styles.altHeadline}>{topic?.attributes[altHeadlineSlug]}</span>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <NegativeMobile>
        <div className={cx(styles.swiperWrapper, isTravels && styles._isTravels)}>
          <div
            ref={node => (this.container = node)}
            className={styles.container}
          >
            <div className={styles.wrapper}>
              {slides}
            </div>
          </div>
        </div>
      </NegativeMobile>
    );
  }
}

MainSwipe.displayName = 'MainSwipe';

export { MainSwipe as StorybookComponent };

export default withTheme(MainSwipe);
