import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.styl';

export default function GradientMask(props) {
  const {
    children,
    className,
    hideEllipsis,
    containerType,
  } = props;

  return (
    <div
      className={cx(
        styles.gradientMask,
        className,
        hideEllipsis && styles._hideEllipsis,
        containerType && styles._hasContainerType,
        containerType && styles['_' + containerType]
      )}
    >
      {children}
    </div>
  );
}

GradientMask.propTypes = {
  className: PropTypes.string,
  hideEllipsis: PropTypes.bool,
  containerType: PropTypes.oneOf([
    'cardPvp1',
    'cardPvp2',
    'topicCoverDesktop',
    'topicCoverMobile',
    'reviews',
  ]),
};
