import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';
import { denormalizeData } from 'core/utils/api';
import modelPropTypes, { rubricAttributes, topicAttributes } from 'core/utils/prop-types/model';

import bindPropsHOC from 'core/components/bindProps';

import withPageHocs from 'core/components/withPageHocs';
import { withBreakpoint } from 'core/components/breakpoint';

import Page from 'core/components/Page';
import { Indent } from 'core/components/Wrappers';

import RubricHeader from 'site/components/RubricHeader';
import SectionNews from 'site/components/SectionNews';
import Skeleton from 'site/components/SectionNews/Skeleton';
import Brands from 'site/components/Brands';
import MoreArticles from 'site/components/MoreArticles';
import Paginator from 'site/components/Paginator';
import NewsComponent from 'site/components/News';


const newsParams = {
  sort: '-published_at',
  include: 'image,authors',
  topic_type: 'news',
  fields: 'headline,alternative_headline,list_headline,link,published_at',
};

function News({ rubricContent, news, isMobile, loading }) {
  const {
    meta_title: metaTitle,
    meta_description: metaDescription,
  } = rubricContent?.data?.attributes || {};

  const indent = isMobile ? '32rem' : '80rem';
  const paginatorOffset = news && (news.today.length + news.current.length + news.rest.length);

  return (
    <Page
      title={metaTitle}
      description={metaDescription}
    >
      <RubricHeader title='Новости' />
      <Indent bottom={indent} />
      {loading && <Skeleton />}
      {!loading && (
        <>
          {news.today.length > 0 && (
            <Indent bottom={indent}>
              <SectionNews
                content={{ topics: news.today }}
                sectionTitle='Сегодня'
                columnReverse
              />
            </Indent>
          )}
          <Brands />
          <Indent bottom={indent} />
          {news.current.length > 0 && (
            <Indent bottom={indent}>
              <SectionNews content={{ topics: news.current }} sectionTitle='Актуальное' />
            </Indent>
          )}
          {news.rest.length > 0 && (
            <Indent bottom={indent}>
              <MoreArticles articles={news.rest.slice(0)} sectionTitle='Больше новостей' />
              <Indent bottom={indent} />
              <Paginator
                apiParams={newsParams}
                startOffset={paginatorOffset}
                filteredCount={news.count}
                feedComponent={NewsComponent}
              />
            </Indent>
          )}
        </>
      )}
    </Page>
  );
}

const topicModel = modelPropTypes(topicAttributes);

News.propTypes = {
  isMobile: PropTypes.bool,
  loading: PropTypes.bool,
  rubricContent: PropTypes.shape({
    data: modelPropTypes(rubricAttributes),
  }),
  news: PropTypes.shape({
    today: PropTypes.arrayOf(topicModel),
    rest: PropTypes.arrayOf(topicModel),
    current: PropTypes.arrayOf(topicModel),
    count: PropTypes.number,
  }),
};

const dataProvider = resolve({
  rubricContent: ({ rubricContent, bebopApi, consoleError }) => {
    return rubricContent || bebopApi
      .getRubric({ rubric_slug: 'news' })
      .catch(consoleError('rubric content', null));
  },
  news: async ({ news, bebopApi, consoleError }) => {
    if (news) return news;

    const idsToExclude = [];

    const current = await bebopApi.getTopics({
      limit: 9,
      sort: '-views_count',
      include: 'image,authors',
      topic_type: 'news',
      fields: 'headline,alternative_headline,link,published_at',
      days_period: 7,
    })
      .then(data => {
        const denormalizedData = denormalizeData(data);
        denormalizedData.map(({ id }) => idsToExclude.push(id));
        return denormalizedData;
      })
      .catch(consoleError('current', []));

    const other = await bebopApi.getTopics({
      ...newsParams,
      excluded_ids: idsToExclude,
      with_filtered_count: 1,
      limit: 17,
    })
      .then(data => {
        const denormalizedData = denormalizeData(data);
        denormalizedData.map(({ id }) => idsToExclude.push(id));

        return {
          topics: denormalizedData,
          count: data?.meta.filtered_count,
        };
      })
      .catch(consoleError('other', { topics: [], count: 0 }));

    return {
      current,
      today: other.topics.slice(0, 9),
      rest: other.topics.slice(9),
      count: other.count,
    };
  },
});

const Loader = bindPropsHOC({ loading: true })(News);
export default withBreakpoint(withPageHocs(dataProvider, Loader)(News));
