import PropTypes from 'prop-types';
import { useState,  useRef, useEffect, useCallback } from 'react';
import cx from 'classnames';

import { throttle } from 'core/libs/lodash';

import { Mobile, withBreakpoint } from 'core/components/breakpoint';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import SectionHeader from 'site/components/SectionHeader';

import GuidesCard from './GuidesCard';
import Arrows from './Arrows';

import styles from './index.styl';

const MIN_SCREEN_HEIGHT = 550;
const MAX_SCREEN_HEIGHT = 1098;
const MIN_IMAGE_HEIGHT = 300;
const MAX_IMAGE_HEIGHT = 735;

function Guides(props) {
  const {
    guides: {
      topics,
      count,
    },
    isDesktop,
  } = props;

  const guidesRef = useRef(null);
  const [guidesState, setGuidesState] = useState(3);
  const [imageHeight, setImageHeight] = useState(MAX_IMAGE_HEIGHT);

  const handleScroll = useCallback(() => {
    if (guidesRef.current) {
      const relativeOffsetTop = guidesRef.current.offsetTop;

      if (relativeOffsetTop > 0 && relativeOffsetTop < 1000) {
        setGuidesState(3);
      } else if (relativeOffsetTop >= 1000 && relativeOffsetTop < 2000) {
        setGuidesState(2);
      } else if (relativeOffsetTop >= 2000 && relativeOffsetTop < 3000) {
        setGuidesState(1);
      }
    }
  }, []);

  const throttledHandleScroll = (throttle(handleScroll, 500));

  const calculateImageHeight = (screenHeightRem) => {
    if (screenHeightRem <= MIN_SCREEN_HEIGHT) {
      return MIN_IMAGE_HEIGHT;
    } else if (screenHeightRem >= MAX_SCREEN_HEIGHT) {
      return MAX_IMAGE_HEIGHT;
    }
    const ratio = (screenHeightRem - MIN_SCREEN_HEIGHT) / (MAX_SCREEN_HEIGHT - MIN_SCREEN_HEIGHT);
    return MIN_IMAGE_HEIGHT + ratio * (MAX_IMAGE_HEIGHT - MIN_IMAGE_HEIGHT);
  };

  const updateImageHeight = useCallback(() => {
    const screenHeightPx = window.innerHeight;
    const newHeight = calculateImageHeight(screenHeightPx);
    setImageHeight(newHeight);
  }, []);

  useEffect(() => {
    if (isDesktop) {
      updateImageHeight();
      window.addEventListener('resize', updateImageHeight);
      return () => window.removeEventListener('resize', updateImageHeight);
    }
    return undefined;
  }, [isDesktop, updateImageHeight]);

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll);

    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
    };
  }, [throttledHandleScroll]);

  const nextHandler = useCallback(() => {
    if (guidesState !== 3) {
      setGuidesState(prevGuidesState => prevGuidesState + 1);
    }
  }, [guidesState]);

  const prevHandler = useCallback(() => {
    if (guidesState !== 1) {
      setGuidesState(prevGuidesState => prevGuidesState - 1);
    }
  }, [guidesState]);

  if (count === 0) return null;

  return (
    <div className={styles.guides}>
      <div className={styles.header}>
        <SectionHeader link='/guides' count={count}>Гайды</SectionHeader>
        <Mobile>
          <Arrows prevHandler={prevHandler} nextHandler={nextHandler} />
        </Mobile>
      </div>
      <div className={cx(styles.cards, styles[`_state_${guidesState}`])} ref={guidesRef}>
        {topics.map((topic, index) => (
          <div key={topic.id} className={styles.guide}>
            <GuidesCard
              topic={topic}
              index={index}
              maxImageHeight={imageHeight}
            />
          </div>
        ))}
      </div>
    </div>
  );
}


Guides.propTypes = {
  guides: PropTypes.shape({
    topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
    count: PropTypes.number,
  }),
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(Guides);

