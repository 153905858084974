import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import withPageHocs from 'core/components/withPageHocs';
import { withBreakpoint } from 'core/components/breakpoint';
import bindPropsHOC from 'core/components/bindProps';

import Page from 'core/components/Page';
import { Indent } from 'core/components/Wrappers';

import RubricTopics from 'site/components/RubricTopics';
import RubricHeader from 'site/components/RubricHeader';
import Skeleton from 'site/components/SectionNews/Skeleton';

import CardHorizontal from 'site/cards/CardHorizontal';
import CardVertical from 'site/cards/CardVertical';
import CardRect1 from 'site/cards/CardRect1';
import CardFullwidth from 'site/cards/CardFullwidth';
import CardPvp2 from 'site/cards/CardPvp2';


const LIMIT = 18;
const LIST_LIMIT = 3;

const RUBRIC_PAGE_MAP = {
  'articles': {
    list: 'statyi-v-rubrike',
    firstTitle: 'Новые статьи',
    secondTitle: 'Больше статей',
  },
  'guides': {
    list: 'gaydy-v-rubrike',
    firstTitle: 'Новые гайды',
    secondTitle: 'Больше гайдов',
  },
  'test-drive': {
    list: 'test-drayvy-v-rubrike',
    firstTitle: 'Новые тест-драйвы',
    secondTitle: 'Больше тест-драйвов',
  },
  'lists': {
    list: 'podborki-v-rubrike',
    firstTitle: 'Новые подборки',
    secondTitle: 'Больше подборок',
  },
  'travels': {
    list: 'puteshestviya-v-rubrike',
    firstTitle: 'Новые путешествия',
    secondTitle: 'Больше путешествий',
  },
  'reviews': {
    list: 'obzory-v-rubrike',
    firstTitle: 'Новые обзоры',
    secondTitle: 'Больше обзоров',
  },
};


function RubricPage(props) {
  const {
    rubricTopics,
    rubric,
    isMobile,
    loading,
  } = props;

  const {
    topics,
    topicsFromList,
  } = rubricTopics || {};

  const {
    content,
    filteredCount,
  } = topics || {};

  if (content?.length === 0 && topicsFromList?.length === 0) return null;

  const {
    title,
    meta_title: metaTitle,
    meta_description: metaDescription,
    slug,
  } = rubric?.data?.attributes || {};

  return (
    <Page
      title={metaTitle}
      description={metaDescription}
    >
      <RubricHeader title={title} />
      <Indent bottom={isMobile ? '32rem' : '80rem'} />
      {loading && <Skeleton />}
      {!loading && (
        <RubricTopics
          apiParams={{
            rubric: slug,
            sort: '-published_at',
          }}
          topicsFromList={topicsFromList}
          topics={content}
          firstTitle={RUBRIC_PAGE_MAP[slug].firstTitle}
          secondTitle={RUBRIC_PAGE_MAP[slug].secondTitle}
          startOffset={LIMIT}
          filteredCount={filteredCount}
        />
      )}
    </Page>
  );
}

RubricPage.propTypes = {
  rubric: PropTypes.object,
  rubricTopics: PropTypes.shape({
    topics: PropTypes.object,
    topicsFromList: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  }),
  isMobile: PropTypes.bool,
  loading: PropTypes.bool,
};

const dataProvider = resolve({
  rubricTopics: async ({ bebopApi, rubricTopics, consoleError, match }) => {
    try {
      if (rubricTopics) return rubricTopics;

      const { params: { rubricSlug } } = match;

      const topicsFromList = await bebopApi
        .getTopics({
          limit: LIST_LIMIT,
          sort: 'list',
          list: RUBRIC_PAGE_MAP[rubricSlug]?.list,
          include: filterRequiredParams([CardFullwidth, CardVertical, CardHorizontal, CardRect1, CardPvp2], 'include'),
          fields: filterRequiredParams([CardFullwidth, CardVertical, CardHorizontal, CardRect1, CardPvp2], 'fields'),
        })
        .then(denormalizeData)
        .catch(consoleError('topics from list on rubric page', []));

      const excludedIds = topicsFromList.map(topic => topic.id);
      const topics = await bebopApi
        .getTopics({
          limit: LIMIT - LIST_LIMIT,
          sort: '-published_at',
          rubric: rubricSlug,
          excluded_ids: excludedIds,
          with_filtered_count: 1,
          include: filterRequiredParams([CardFullwidth, CardVertical, CardHorizontal, CardRect1, CardPvp2], 'include'),
          fields: filterRequiredParams([CardFullwidth, CardVertical, CardHorizontal, CardRect1, CardPvp2], 'fields'),
        })
        .then(data => ({
          content: denormalizeData(data),
          filteredCount: data.meta.filtered_count + excludedIds.length,
        }))
        .catch(consoleError('topics on rubric page', { content: [] }));

      return {
        topicsFromList,
        topics,
      };
    } catch (e) {
      consoleError('Error in rubricTopics', e);

      return {
        topicsFromList: [],
        topics: {
          content: [],
        },
      };
    }
  },

  rubric: (props) => {
    const {
      bebopApi,
      renderError,
      match: { params: { rubricSlug } },
      rubric,
    } = props;

    return rubric || bebopApi
      .getRubric({
        rubric_slug: rubricSlug,
      })
      .catch(renderError);
  },
});

const Loader = bindPropsHOC({ loading: true })(RubricPage);

export default withBreakpoint(withPageHocs(dataProvider, Loader)(RubricPage));
